.leaflet-container {
  width: 100%;
  height: 506px;
}

button {
    font-size: 14px;
   line-height: 14px;
   color: #fff;
   display: inline-block;
   padding: 12px 32px;
   width: auto;
   border-radius: 35px;
   text-transform: capitalize;
   background: #960014;
   border-style: none;
}

button:hover  {
  background-color: #610000;
  border-color: #610000;
}

button:active  {
  background-color: #610000;
  border-color : #610000;
}

button:focus  {
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
}

.about-button{
  padding-top: 30px;
}


